export default {
  "Tristan Chevrier": {
    pageSlug: "/blog/author/tristan-chevrier/",
    pageUri: "/tristan-chevrier/",
    role: "Rédigé par",
    description: "Tristan Chevrier est rédacteur et responsable de la publication des articles de Mia.co. Après une licence de philosophie, il s’est spécialisé dans les questions de sexualité et de bien-être. Tous ses articles sont relus par le comité scientifique de la plateforme.",
    image: 'pp_default.jpeg',
    slug: 'tristanc',
    name: "Tristan Chevrier"
  },
  "Gilbert Bou Jaoudé": {
    pageSlug: "/blog/author/dr-gilbert-bou-jaoude/",
    pageUri: "/dr-gilbert-bou-jaoude/",
    role: "Révisé par le Docteur",
    image: 'pp_gilbert.png',
    slug: 'gilbert',
    name: "Gilbert Bou Jaoudé"
  }
};
