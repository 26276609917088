import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import { graphql } from "gatsby";
import BlogLayout from "components/common/blog/BlogLayout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Blocks from "templates/Blog/Gutenberg/Blocks";

import './styles.sass';

import StructuredPerson from "components/common/meta/StructuredPerson";
import authorMap from 'components/Author/data'

// TODO: stop relying on wordpress for that component
class Page extends React.Component {
  render() {
    const {
      location: { search },
      data: { page },
    } = this.props;

    let author
    for (const k in authorMap)
      if (authorMap[k].pageUri == page.uri)
        author = authorMap[k]

    if (!author) throw 'author not found';

    return (
      <BlogLayout>
        <CharlesMeta
          title={page.title}
          description={page.title}
        />
        <Container className="mt-3 author-page">
          <Row>
            <StructuredPerson person={author} />
            <Col>
              <Blocks context={page} blocks={page.blocks} />
            </Col>
          </Row>
        </Container>
      </BlogLayout>
    );
  }
}

export default Page;

export const pageQuery = graphql`
  query BlogAuthorPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")
      modified(formatString: "MMMM DD, YYYY")
      link
      slug
      status
      uri
      blocks {
        ...GutenbergBlock
      }
    }
  }
`;
